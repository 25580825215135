<template>
	<v-container>
		<v-row>
			<v-col cols="6">
				<v-card>
					<v-card-text>
						<editor :model-value.sync="post.text" :text-length.sync="post.length" type="simple"></editor>
						<v-row dense>
              <v-col cols="12">
                <v-checkbox v-model="post.addButton" label="Добавить кнопку"></v-checkbox>
              </v-col>
							<v-col v-if="post.addButton" cols="8"><v-text-field v-model="post.button_link" placeholder="Ссылка в кнопке" label="Ссылка в кнопке" outlined></v-text-field></v-col>
							<v-col v-if="post.addButton" cols="4"><v-text-field v-model="post.button_text" placeholder="Текст в кнопке" label="Текст в кнопке" outlined></v-text-field></v-col>
              <v-col cols="6">
<!--                <v-radio-group v-if="post.id<1" v-model="post.tg_method">-->
                <v-radio-group v-if="post.status !== 'Опубликован'" v-model="post.tg_method">
                  <v-radio :disabled="post.length>1024" value="sendPhoto" label="Фото сверху"></v-radio>
                  <v-radio value="sendMessage" label="Фото снизу"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="6">
                  <v-select v-model="post.channels" :items="channels" :item-value="'id'" item-text="name" outlined multiple label="Канал" placeholder="Канал"></v-select>
              </v-col>
              <v-col cols="6">
                <v-checkbox v-model="post.post_immediately" label="Опубликовать сразу"></v-checkbox>
              </v-col>
              <v-col cols="6">
                <date-picker
                  v-if="!post.post_immediately"
                  v-model="post.planned_at"
                  type="datetime"
                  value-type="format"
                  :show-second="false"
                  :minute-step="5"
                  :hour-options="[10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]"
                  :format="'YYYY-MM-DD H:mm:ss'"
                  :disabled-date="notYesterday"
                  :lang="lang"
                  label="Время"
                  show-hour
                  show-minute
                ></date-picker>
              </v-col>
            </v-row>
          </v-card-text>
					<v-card-actions>
						<v-btn v-if="post.status !== 'Опубликован'" :color="postId > 0 ? 'info' : 'success'" @click="publish">{{postId > 0 ? 'Обновить пост' : 'Отправить пост'}}</v-btn>
            <v-btn v-else color="error" @click="updatePost()">Изменить опубликованный пост</v-btn>
					</v-card-actions>
				</v-card>
			</v-col>
			<v-col cols="6">
				<v-img
						v-if="post.image"
						:src="'https://backend.telestatic.ru/upload/simple_images/' + post.image"
						max-height="400"
						border="1"
				></v-img>
				<vue-dropzone
						id="dropzone"
						ref="dropzone"
						class="mb-3"
						:options="dropzoneOptions"
						@vdropzone-success="uploadSuccess"
				></vue-dropzone>
			</v-col>
		</v-row>
		<v-dialog
				v-model="dialog"
				transition="dialog-bottom-transition"
				max-width="600"
		>
			<v-card>
				<v-card-text class="justify-center">
					<!--						<v-progress-linear-->
					<!--								v-model="progress"-->
					<!--								:buffer-value="100"-->
					<!--						></v-progress-linear>-->
					<v-progress-linear
							v-if="publishInProgress"
							indeterminate
							height="20"
							color="green"
					></v-progress-linear>
					<p>{{currentAction}}</p>
				</v-card-text>
			</v-card>
		</v-dialog>
	</v-container>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import qs from 'qs'
import _ from 'lodash'
import Editor from '../../components/posts/Editor'
import httpClient from '../../services/http.service'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
export default {
  name: 'SimplePost',
  components: {Editor, vueDropzone: vue2Dropzone, DatePicker},
  data(){
    const token = localStorage.getItem('ACCESS_TOKEN')
    return{
      postId: 0,
      dialog: false,
      publishInProgress: false,
      currentAction: '',
      defaultPost: {id: 0, title: '', text: 'Тут текст', image: false, channels: [1, 2], button_text: '💥 Перейти к товару 💥', button_link: '', length: 0, tg_method: 'sendPhoto', planned_at: '', addButton: false, post_immediately: true},
      post: {id: 0, title: '', text: '', image: false, channels: [1, 2], button_text: '💥 Перейти к товару 💥', button_link: '', length: 0, tg_method: 'sendPhoto', planned_at: '', addButton: false, post_immediately: true},
      dropzoneOptions: {
        url: 'https://backend.telestatic.ru/upload/simple-post',
        thumbnailWidth: 150,
        maxFilesize: 100,
        headers: { Authorization: `Bearer ${token}` }
      },
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
        monthBeforeYear: true,
      },
    }
  },
  watch:{
    title(v){
      this.post.title = v
    },
    tgMethod(v){
      this.post.tg_method = v
    }
  },
  computed:{
    channels(){
      return this.$store.state.channels
    },
    title(){
      return this.post.text.substring(0, 30)
    },
    tgMethod(){
      if (this.post.length>1024) return 'sendMessage'
      return 'sendPhoto'
    }
  },
  created() {
    console.log('post id: ', this.$route.params.id)
    if (this.$route.params.id !== undefined){
      this.postId = Number(this.$route.params.id)
    }else{
      this.postId = 0
    }
    this.post = this.defaultPost
    this.$store.dispatch('getChannels')
    if (_.has(this.$route.params, 'id')){
      httpClient.get('simple-posts/posts?pid=' + this.$route.params.id).then(({data})=>{
        this.post = {...this.post, ...data}
        this.post.channels = data.channels
        this.post.planned_at = data.planned_at
        if (!_.isEmpty(data.button_link)){
          this.post.addButton = true
        }
      })
    }
  },
  methods:{
    uploadSuccess(file, response){
      console.log('upload success', response)
      this.$set(this.post, 'image', response.url)
      this.$set(this.post, 'photo_id', response.photo_id)
    },
    notYesterday(date){
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      return date < today
    },
    publish(){
      this.dialog = true
      this.publishInProgress = true
      this.currentAction = 'Сохраняем...'
      let url = 'simple-posts/publish'
      if (this.post.id>0){url = 'simple-posts/publish?update=true'}
      httpClient.post(url, qs.stringify(this.post)).then(({data})=>{
        this.publishInProgress = false
        console.log('result: ', data)
        if (data.success){
          this.post = this.defaultPost
          this.currentAction = 'Йоху, успешно!'
        }else{
          this.currentAction = data.errors
        }
      })
    },
    updatePost(){
      httpClient.post(`simple-posts/update?pid=${this.postId}`, qs.stringify({text: this.post.text, link: this.post.button_link})).then(({data})=>{
        if (data.success){
          this.post = this.defaultPost
          this.currentAction = 'Успешно отредактировали'
        }else{
          this.currentAction = `Ошибка! ${data}`
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
